var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-3" },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
        },
        [_vm._v("Score Card")]
      ),
      _c("v-card-text", [
        _c(
          "div",
          { staticClass: "my-3 mx-auto", staticStyle: { width: "860px" } },
          [
            _c(
              "div",
              { staticClass: "d-flex justify-space-between mb-3" },
              [
                _c(
                  "v-btn",
                  {
                    staticStyle: { width: "120px" },
                    attrs: { rounded: "", color: "accent lighten-2" }
                  },
                  [_vm._v("Category")]
                ),
                _c(
                  "v-btn",
                  {
                    staticStyle: { width: "520px" },
                    attrs: { rounded: "", color: "accent lighten-2" }
                  },
                  [_vm._v("Description")]
                ),
                _c(
                  "v-btn",
                  {
                    staticStyle: { width: "210px" },
                    attrs: { rounded: "", color: "accent lighten-2" }
                  },
                  [_vm._v("Recommendation")]
                )
              ],
              1
            ),
            _vm._l(_vm.items, function(item, index) {
              return [
                _c(
                  "v-row",
                  {
                    key: index,
                    staticClass: "align-center",
                    attrs: { "no-gutters": "" }
                  },
                  [
                    _c(
                      "v-col",
                      { key: "col-left-" + index },
                      [
                        _c(
                          "v-chip",
                          {
                            staticStyle: { width: "120px", height: "48px" },
                            attrs: {
                              label: "",
                              color:
                                index % 2 == 0
                                  ? "accent lighten-1"
                                  : "accent darken-3"
                            }
                          },
                          [_vm._v(_vm._s(item.category))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { key: "col-main-" + index },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "mr-2",
                            staticStyle: { width: "520px", height: "48px" },
                            attrs: {
                              rounded: "lg",
                              flat: "",
                              outlined: "",
                              color: "grey lighten-3"
                            }
                          },
                          [
                            _c(
                              "v-card-text",
                              { staticClass: "py-1 my-0 font-weight-bold" },
                              [_vm._v(" " + _vm._s(item.Description) + " ")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { key: "col-right-" + index, staticClass: "text-right" },
                      [
                        _c(
                          "v-chip",
                          {
                            staticClass: "text-uppercase",
                            staticStyle: { width: "210px" },
                            attrs: { color: item.color }
                          },
                          [
                            _c(
                              "v-avatar",
                              { attrs: { left: "" } },
                              [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                              1
                            ),
                            _vm._v(" " + _vm._s(item.Action))
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }