<template>
    <v-card class="my-3">
        <v-card-title
            class="primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
            >Score Card</v-card-title
        >
        <v-card-text>
            <div class="my-3 mx-auto" style="width: 860px">
                <div class="d-flex justify-space-between mb-3">
                    <v-btn rounded style="width: 120px" color="accent lighten-2"
                        >Category</v-btn
                    >
                    <v-btn rounded style="width: 520px" color="accent lighten-2"
                        >Description</v-btn
                    >
                    <v-btn rounded style="width: 210px" color="accent lighten-2"
                        >Recommendation</v-btn
                    >
                </div>
                <template v-for="(item, index) in items">
                    <v-row :key="index" no-gutters class="align-center">
                        <v-col :key="`col-left-${index}`">
                            <v-chip
                                style="width: 120px; height: 48px"
                                label
                                :color="
                                    index % 2 == 0
                                        ? 'accent lighten-1'
                                        : 'accent darken-3'
                                "
                                >{{ item.category }}</v-chip
                            >
                        </v-col>
                        <v-col :key="`col-main-${index}`">
                            <v-card
                                class="mr-2"
                                style="width: 520px; height: 48px"
                                rounded="lg"
                                flat
                                outlined
                                color="grey lighten-3"
                            >
                                <v-card-text class="py-1 my-0 font-weight-bold">
                                    {{ item.Description }}
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col :key="`col-right-${index}`" class="text-right">
                            <v-chip
                                :color="item.color"
                                class="text-uppercase"
                                style="width: 210px"
                            >
                                <v-avatar left>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-avatar>
                                {{ item.Action }}</v-chip
                            >
                        </v-col>
                    </v-row>
                </template>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import WithRecommendations from "@components/screen/mixins/WithRecommendations";
export default {
    name: "screening-scorecard",
    components: {},
    mixins: [WithRecommendations],
    props: {
        scorecard: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Matched",
                    align: "center",
                    value: "Match",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Category",
                    align: "start",
                    value: "category",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },

                {
                    text: "Value",
                    align: "start",
                    value: "Value",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Result",
                    align: "start",
                    value: "Action",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
                {
                    text: "Description",
                    align: "start",
                    value: "Description",
                    class:
                        "text-subtitle-2 font-weight-medium primary--text text-uppercase",
                },
            ],
        };
    },
    computed: {
        items() {
            let items = [];
            for (const category in this.scorecard.scorecard) {
                this.scorecard.scorecard[category].rules.forEach((rule) => {
                    if (rule.Match) {
                        items.push({
                            ...rule,
                            category,
                            ...this.recommendationInfo(rule.Action),
                        });
                    }
                });
            }
            return items;
        },
    },
    watch: {},

    methods: {},
};
</script>

<style scoped>
</style>